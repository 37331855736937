@import '@aws-amplify/ui-react/styles.css';
@import 'rc-select/assets/index.css';
@import 'buildinfo.scss';

$white: white;
$white2: #EFF0F0;
$black: black;
$red: #ff3030;
$darkWhite: #fafafa;
$lightGray: #e4e4e4;
$darkGray: #919191;
$darkGray2: #4e5153;
$lightPink: #ffe9e9;

html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 18px;
  @media(max-width: 991px) {
    font-size: 14px;
  }
}
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $darkGray;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: $white;
}

:root {
  --amplify-components-fieldcontrol-error-border-color: #{$red};
  --amplify-components-fieldmessages-error-color:  #{$red};
  --amplify-components-fieldcontrol-error-focus-box-shadow:  #{$red};
  --amplify-components-checkbox-button-error-border-color: #{$red};
  --amplify-components-fieldcontrol-padding-block-start: 5px;
  --amplify-components-fieldcontrol-padding-block-end: 5px;
}

.form-control  {
  box-sizing: border-box;
  color: var(--amplify-components-fieldcontrol-color) !important;
  font-size: var(--amplify-components-fieldcontrol-font-size) !important;
  line-height: var(--amplify-components-fieldcontrol-line-height) !important;
  padding-block-start: var(--amplify-components-fieldcontrol-padding-block-start) !important;
  padding-block-end: var(--amplify-components-fieldcontrol-padding-block-end) !important;
  padding-inline-start: var(--amplify-components-fieldcontrol-padding-inline-start) !important;
  padding-inline-end: var(--amplify-components-fieldcontrol-padding-inline-end) !important;
  transition: all var(--amplify-components-fieldcontrol-transition-duration) !important;
  width: 100% !important;
  border-color: var(--amplify-components-fieldcontrol-border-color) !important;
  border-radius: 10px !important;
  height: 36px !important;
  border-style: var(--amplify-components-fieldcontrol-border-style) !important;
  border-width: var(--amplify-components-fieldcontrol-border-width) !important;
  outline-color: var(--amplify-components-fieldcontrol-outline-color) !important;
  outline-style: var(--amplify-components-fieldcontrol-outline-style) !important;
  outline-width: var(--amplify-components-fieldcontrol-outline-width) !important;
  outline-offset: var(--amplify-components-fieldcontrol-outline-offset) !important;
  gap: var(--amplify-components-field-gap) !important;
  padding-left: 43px !important;
}

.form-control:disabled, .amplify-input[disabled] {
  color: rgba(255,255,255, 0.5) !important;
  cursor: var(--amplify-components-fieldcontrol-disabled-cursor) !important;
  border-color: rgba(255,255,255, 0.1) !important;
  background: transparent !important;
  opacity: 1;
}
.custom-radio-buttons {
  min-height: 38px;
  .amplify-radio {
    margin-right: 10px;
  }
  .amplify-text {
    color: #fff;
    margin: 0 5px;
  }
  .amplify-radio__button {
    background-color: transparent;
    border-color: #CACACA;
    cursor: pointer;
    &:before {
      background-color: transparent;
    }
  }
  .amplify-radio__input {
    &:checked + .amplify-radio__button {
      border-color: #4287E1!important;
      cursor: default;
      &:before {
        background-color: #4287E1;
      }
    }
  }
}

.custom-checkbox {
  .amplify-checkbox__label {
    margin-left: 5px;
    color: #fff;
    a {
      color: #fff;
    }
  }
  .amplify-checkbox__button {
    &:before {
      border: 1px solid #fff;
      box-shadow: none;
    }
  }
  .amplify-checkbox__icon {
    background-color: #4287E1!important;
  }
}
.custom-input {
  label {
    color: #fff;
  }
  .amplify-input:not(.amplify-input--error) {
    border-color: #CACACA;
    &:focus, &:hover {
      border-color: #fff;
    }
  }
  .amplify-input {
    box-shadow: none;
    &::placeholder {
      color: #CACACA;
    }
  }
}
.custom-select {
  .amplify-label {
    color: #fff;
  }
  .amplify-select {
    border-radius: 10px;
    border: 1px solid #CACACA;
    box-shadow: none!important;
    color: #fff;
    &:hover, &:focus {
      border-color: #fff;
    }
    option {
      background-color: rgba(1, 1, 4, 0.6);
      color: #fff;
    }
  }
}

.flag-dropdown {
  margin: 2px;
  border: none !important;
  background-color: transparent!important;
}

.selected-flag {
  border-radius: 8px 0 0 8px !important;
  background-color: transparent!important;
}
.react-tel-input .country-list {
  border-radius: 5px!important;
  background-color: rgba(136, 136, 136, 1)!important;
}
.react-tel-input .country-list .country {
  font-weight: 500;
  color: $white!important;
}
.react-tel-input .country-list .country:hover {
  background-color: $black!important;
}
.react-tel-input .country-list .country.highlight {
  background-color: $black!important;
}
.react-tel-input .country-list .country .dial-code {
  color: $white!important;
  font-weight: 700;
}
.react-tel-input .country-list .country
.amplify-radio__input:checked + .amplify-radio__button {
  color: $black !important;
}

.amplify-radio__input:focus + .amplify-radio__button {
  border-color: $black !important;
}

.amplify-checkbox__icon {
  background-color: $black !important;
}

.form-control:disabled + .flag-dropdown {
  background-color:  var(--amplify-components-fieldcontrol-disabled-background-color) !important;
  border: none;
}

.amplify-alert--error {
  color: $red !important;
  background-color: $lightPink !important;
}

.amplify-field__error-message {
  line-height: 1;
  @media(min-width: 576px) {
    position: absolute;
    top: 100%;
  }
}

.amplify-field {
  gap: 3px;
  margin-bottom: 10px;
  @media(min-width: 576px) {
    margin-bottom: 16px;
    position: relative;
  }
}

.age-input {
  .amplify-field {
    @media(min-width: 576px) {
      margin-bottom: 30px;
    }
  }
}

.phone-with-error input {
  border-color: var(--amplify-components-fieldcontrol-error-border-color) !important;
}

.custom-button {
  width: 100%;
  border-radius: 30px;
  font-weight: 500;
  border: none;
  padding: 10px 20px;
  box-shadow: none!important;

  &:hover, &:active, &:focus {
    color: $white;
    background-color: #324AB2;
    border-color: $darkWhite;
  }
}
.custom-button.small {
  padding-top: 5px;
  padding-bottom: 5px;
}

.custom-button-primary {
  background: $darkWhite;

  &:focus, &:hover {
    color: $black;
    background: $darkWhite;
    box-shadow: none;
  }

  &:active {
    background: $black;
    border-color: $black;
    color: $darkWhite;;
  }
  

  @media (hover: hover) {
    &:hover {
      background: $black;
      border-color: $black;
      color: $darkWhite;;
    }
  }
}

.custom-button-secondary {
  background: #324AB2;
  color: $white;

  &.amplify-button--loading {
    background: #324AB2!important;
    color: $white !important;
    opacity: 0.7;
  }
}

.custom-button-dark {
  background: $black;
  color: $white;
  &:hover, &:active, &:focus {
    background: #324AB2!important;
    color: $white!important;
  }
}

.custom-button-error {
  border: 'none';
  background: 'transparent';
  color: $red;
  @media(min-width: 576px) {
    &:hover, &:active {
      background: $red;
      border-color: $red;
      color: $white;
    }
  }
}

.custom-button-disable {
  background: $lightGray !important;
  color: $darkGray !important;
}

.react-tel-input {
  font-family: inherit !important;
}
.react-tel-input .form-control {
  color: $white!important;
  background-color: transparent!important;
  border-color: rgba(255,255,255, 0.5) !important;
  &:focus, &:hover, &:active {
    border-color: #fff!important;
  }
  @media(max-width: 576px) {
    height: 33px!important;
  }
}
.amplify-table {
  border-radius: 16px;
  background: rgba(1, 1, 4, 0.60);
}

.amplify-table__td, .amplify-table__th {
  border: none!important;
  color: #fff!important;
  padding: 14px 24px;
  @media(max-width: 576px) {
    padding: 10px;
  }
}
.amplify-table__head {
  .amplify-table__td, .amplify-table__th {
    border-bottom: 1px solid rgba(255, 255, 255, 0.20)!important;
  }
}
.amplify-table__row {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 24px;
    left: 24px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.20);
  }
  &:last-of-type {
    &:after {
      content: none;
    }
  }
}

[data-amplify-authenticator][data-variation=modal] {
  &:after {
    //content: $buildinfo;
    position: absolute;
    right: 20px;
    bottom: 15px;
  }
}

.webpage-version {
  &:after {
    //content: $buildinfo;
  }
}

.amplify-passwordfield {
  flex-direction: row;
}

.amplify-passwordfield .amplify-field-group {
  width: 100%;
}

.amplify-passwordfield::after {
  content: 'ⓘ';
  font-size: 1.4em;
  padding-left: .4em;
}

.amplify-passwordfield:hover .amplify-field-group:not(:hover)::after {
  content: 'A passphrase is like a password, but longer and with multiple words';

  display: block;
  position: absolute;
  right: -150px;
  width: 300px;
  top: -60px;

  background: $black;
  color: $white;
  border-radius: 3px;
  padding: 5px 10px;
}

//rc-select
.rc-select {
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  .rc-select-selection-placeholder {
    position: absolute;
    top: 7px;
    left: 10px;
  }
}
.rc-select-selection-search {
  margin: 0 5px;
}
.rc-select-arrow {
  transition: all 0.3s ease;
  transform: translateY(2px);
}
.rc-select-open {
  .rc-select-arrow {
    transform: rotate(180deg);
  }
}
.rc-select-multiple {
  .rc-select-selector {
    .rc-select-selection-item {
      display: flex;
      align-items: center;
      border-radius: 10px;
      background-color: rgba(1, 1, 4, 0.6);
    }
    .rc-select-selection-search-input {
      min-height: 30px;
      background-color: transparent;
      color: #fff;
      font-size: 18px;
    }
  }
}
.rc-select-selector {
  min-height: 37px;
  border-color: #CACACA!important;
  border-radius: 10px;
  padding: 3px;
  .rc-select-selection-item {
    padding: 4px 8px;
    color: #fff;
  }
  .rc-select-selection-overflow-item {
    align-items: center;
  }
}
.rc-select-focused .rc-select-selector {
  border-color: #CACACA!important;
}
.rc-select-selection-overflow {
  align-items: center;
}
.rc-select-selection-item-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: rgba(56, 56, 56, 0.50);
  border-radius: 50%;
  margin-left: 8px;
  cursor: pointer;
}
.rc-select-selection-item-remove-icon {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 2px;
}
.rc-select-dropdown {
  border: none;
  border-radius: 8px;
  background-color: rgba(56, 56, 56, 0.50);
  backdrop-filter: blur(30px);
  padding: 8px;
  z-index: 10;
}
.rc-virtual-list-holder-inner {
  color: #fff;
}
.rc-select-item {
  padding-left: 35px;
}
.rc-select-item-option {
  .rc-select-item-option-state {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: 6px;
    right: initial;
    left: 8px;
  }
}
.rc-select-item-option-active, .rc-select-item-option-selected {
  background-color: rgba(1, 1, 4, 0.6);
  border-radius: 8px;
}
.rc-select-item-option-selected {
  .rc-select-item-option-state {
    background-color: rgba(56, 56, 56, 0.50);
  }
}
.rc-select-show-arrow {
  .rc-select-arrow {
    right: 15px;
    top: 7px;
  }
}
